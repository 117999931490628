import { 
    Button, 
    Container, 
    Row, 
    Col, 
    Modal,
    Form
} from 'react-bootstrap';
import { CiTrash } from "react-icons/ci";
import PropTypes from 'prop-types';
import { useState } from 'react';

ModalJustificativaCancelamento.propTypes = {

    showModal: PropTypes.bool.isRequired,
    setShowModal: PropTypes.func.isRequired,
    funcPos: PropTypes.func

}

export default function ModalJustificativaCancelamento({showModal, setShowModal, funcPos}) {

    const [justificativaCancelamento, setJustificativaCancelamento] = useState("");

    return(
        <Modal
        show={showModal}
        onHide={() => { setShowModal(false) } }
        keyboard={false}
        id="modalSucess"
        centered
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    <Container>
                        <Row>
                            <Col
                                xxl={2}
                                xl={2}
                                md={2}
                                sm={2}
                                xs={2}
                            >
                                <CiTrash  size={25}/>
                            </Col>
                            <Col>
                                <h4>Cancelamento NFE</h4> 
                            </Col>
                        </Row>
                    </Container>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row style={{marginBottom: "5%"}}>
                    <Col style={{textAlign: "center", alignItems: "center"}}>
                        <Form.Label>Justificativa</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            value={justificativaCancelamento}
                            onChange={(e) => setJustificativaCancelamento(e.target.value)}
                        />
                    </Col>
                </Row>
                <Row >

                    <Col>
                        <div className="d-grid gap-2">
                            <Button
                            variant="success"
                            onClick={ () => funcPos(justificativaCancelamento)}
                            >OK</Button>
                        </div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
    

}
