import { Container, Row, Col, FloatingLabel, Form, Button, Table } from "react-bootstrap";
import NavbarOp from "../components/Navbar";
import React from "react";
import global from "../global";
import axios from "axios";
import * as Sentry from "@sentry/react";
import ModalErro from "../components/Modal/ModalErro";
import ModalLoad from "../components/Modal/ModalLoad";
import ModalSucesso from "../components/Modal/ModalSucesso";

import { BsArrowLeftSquare } from "react-icons/bs";
import { BsArrowRightSquare } from "react-icons/bs";

export default function ListaCFe(){

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    const [user, setUser] = React.useState(null);
    const [empresaSelecionada, setEmpresaSelecionada] = React.useState(null);
    const [notasCfe, setNotasCfe] = React.useState([]);

    console.log(user);

    const [numCfe] = React.useState(0);
    const [dateDe, setdateDe] = React.useState(new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString());
    const [dataAte, setdataAte] = React.useState(new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString());
    const [pagina, setPagina] = React.useState(1);
    const [totalPaginas, setTotalPaginas] = React.useState(0);
    const [totalRegistros, setTotalRegistros] = React.useState(0);
    //padrão igual do erp
    const [totalPorPagina] = React.useState(15);

    //modal load
    const [showModalLoad, setShowModalLoad] = React.useState(false);

    //Modal Erro
    const [showModalErro, setShowModalErro] = React.useState(false);
    const [msgModalErro, setMsgModalErro] = React.useState("");

    //Modal Sucesso
    const [showModalSucesso, setShowModalSucesso] = React.useState(false);
    const [msgModalSucesso] = React.useState("");

    //functions
    const countCFE = React.useCallback(() => {

        if(empresaSelecionada != null){

            setShowModalLoad(true);
        
            axios.post(global.URL_BASE + global.PATH_CONTAR_CFE, {
                
                idOrgranizacao: empresaSelecionada.idOrganizacao ,
                idFilial: empresaSelecionada.idEmpresa ,
                numeroNFCe: numCfe,
                dataEmissaoDe: dateDe,
                dataEmissaoAte: dataAte
    
            })
            .then(function (response) {
    
                console.log(response);
    
                let data = response.data;
    
                if(data.code == 0){
    
                    //
                    setTotalRegistros(data.content);
    
                }else{
    
                    setMsgModalErro(data.msg);
                    setShowModalErro(true);
    
                }
                
            })
            .catch(function (error) {

                Sentry.captureException(error);

                setMsgModalErro(data.msg);
                setShowModalErro(true);
    
                console.log(error);
    
            })
            .finally(function () {
                
                setShowModalLoad(false);
    
            });

        }

    }, [dataAte, dateDe, empresaSelecionada, numCfe]);

    const carregarCFE = React.useCallback(() => {

        if(empresaSelecionada != null){

            setShowModalLoad(true);
        
            axios.post(global.URL_BASE + global.PATH_LISTACFE, {
                
                idOrgranizacao: empresaSelecionada.idOrganizacao ,
                idFilial: empresaSelecionada.idEmpresa ,
                numeroNFCe: numCfe,
                dataEmissaoDe: dateDe,
                dataEmissaoAte: dataAte,
                numeroPagina: pagina,
                numeroPorPagina: totalPorPagina

            })
            .then(function (response) {

                let data = response.data;

                if(data.code == 0){

                    console.log("DATA LISTA NFCe" , data)

                    //
                    setNotasCfe(data.content == null ? [] : data.content);
                    countCFE();

                }else{

                    setMsgModalErro(data.msg);
                    setShowModalErro(true);

                }
                
            })
            .catch(function (error) {
                
                setMsgModalErro("Ocorreu um erro ao listar as NFCe. Tente novamente: " + error);
                setShowModalErro(true);

                Sentry.captureException(error);

                console.log(error);

            })
            .finally(function () {
                //setShowModalLoad(false);
            });

        }

        

    }, [dataAte, dateDe, empresaSelecionada, numCfe, pagina, totalPorPagina]);

    console.log("NOTAS NFE", notasCfe);
    console.log("TOTAL REGISTRO", totalRegistros);
    console.log("PAGINA", pagina);

    React.useEffect(() => {

        console.log("PAGINA", pagina);
        carregarCFE();

    }, [pagina]);

    React.useEffect(() => {

        setTotalPaginas( parseInt(totalRegistros / totalPorPagina) +1 );

    }, [totalRegistros, totalPorPagina]);

    return(

        <Container fluid style={{maxWidth: "1000px", paddingBottom: "1%", backgroundColor: "#FFF"}}>

            <ModalErro showModal={showModalErro} setShowModal={setShowModalErro} msg={msgModalErro} />
            <ModalLoad showModal={showModalLoad} setShowModal={setShowModalLoad} msg={msgModalSucesso} />
            <ModalSucesso showModal={showModalSucesso} setShowModal={setShowModalSucesso} msg={msgModalSucesso} />

            <NavbarOp user={user} setUser={setUser} empresaSelecionada={empresaSelecionada} setEmpresaSelecionada={setEmpresaSelecionada} />

            <Row style={{marginTop: "1%", textAlign: "center"}}>
                <h1>CFE</h1>
            </Row>

            <hr/>

            <Container style={{marginTop: "1%"}}>

                <Row>
                    <Col
                    xs={12}
                    sm={6}
                    md={6}
                    >
                        <FloatingLabel
                            label="De: "
                            className="mb-3"
                        >
                            <Form.Control 
                                type="date"
                                value={dateDe} 
                                max={dataAte}
                                onChange={(e) => {

                                    setdateDe(e.target.value);

                                }}
                            />
                        </FloatingLabel>
                    </Col>
                    <Col
                    xs={12}
                    sm={6}
                    md={6}
                    >
                        <FloatingLabel
                            label="Até: "
                            className="mb-3"
                        >
                            <Form.Control 
                                type="date"
                                value={dataAte} 
                                max={new Date().getFullYear().toString() + "-" + ( (new Date().getMonth() + 1) <= 9 ? ("0" + (new Date().getMonth() + 1).toString()) : new Date().getMonth() + 1).toString()  + "-" + ( (new Date().getDate()) <= 9 ? ("0" + new Date().getDate().toString()) : (new Date().getDate().toString()) ).toString()}
                                onChange={(e) => {

                                    setdataAte(e.target.value);

                                }}
                            />
                        </FloatingLabel>
                    </Col>
                </Row>
                

            </Container>

            <Row>
                <Col>
                    {totalRegistros > 0 ? <h6>Total de notas: {totalRegistros}</h6> : null}
                </Col>
            </Row>

            <hr />

            <Row 
            style={{marginBottom: "1%"}}
            >
                <Col
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                style={{marginTop: "1%"}}
                >
                
                    <div className="d-grid gap-2">
                        <Button
                        variant="primary"
                        onClick={() => {

                            carregarCFE();

                        }}
                        >Buscar</Button>{' '}
                    </div>
                
                </Col>

                <Col
                xs={12}
                sm={6}
                md={6}
                lg={6}
                xl={6}
                style={{marginTop: "1%"}}
                >
                
                    <div className="d-grid gap-2">
                        <Button
                        variant="primary"
                        onClick={() => {

                            try {

                                setShowModalLoad(true);

                                axios.post(global.URL_BASE + global.PATH_BAIXARXMLCFE, {
                    
                                    idOrganizacao: empresaSelecionada.idOrganizacao ,
                                    idFilial: empresaSelecionada.idEmpresa ,
                                    numeroNFCe: numCfe,
                                    dataEmissaoDe: dateDe,
                                    dataEmissaoAte: dataAte
                    
                                })
                                .then(function (response) {
                    
                                    console.log(response);
                    
                                    let data = response.data;
                    
                                    if(data.code == 0){
                    
                                        console.log("DATA LISTA NFCe" , data)
                    
                                        // Decodificar Base64
                                        const base64Content = data.content;
                                        const binaryString = atob(base64Content);
                                        const len = binaryString.length;
                                        const bytes = new Uint8Array(len);
                                        for (let i = 0; i < len; i++) {
                                            bytes[i] = binaryString.charCodeAt(i);
                                        }

                                        // Criar Blob a partir dos dados binários
                                        const blob = new Blob([bytes], { type: 'application/zip' });

                                        // Criar URL para o Blob
                                        const url = window.URL.createObjectURL(blob);

                                        // Criar elemento de link e acionar o download
                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.setAttribute('download', 'notas_CFE.zip'); // Nome do arquivo a ser baixado
                                        document.body.appendChild(link);
                                        link.click();

                                        // Remover o link do DOM
                                        document.body.removeChild(link);
                                        window.URL.revokeObjectURL(url);
                                        
                    
                                    }else{
                    
                                        setMsgModalErro(data.msg);
                                        setShowModalErro(true);
                    
                                    }
                    
                                    setShowModalLoad(false);
                                    
                                })
                                .catch(function (error) {

                                    Sentry.captureException(error);

                                    setMsgModalErro("Ocorreu um erro ao baixar as CFE. Tente novamente: " + error);
                                    setShowModalErro(true);     
                                    
                                    setShowModalLoad(false);
                    
                                    console.log(error);
                    
                                });
                                
                            } catch (error) {
                                
                                setMsgModalErro(error.message);
                                setShowModalErro(true);
                                setShowModalLoad(false);

                                Sentry.captureException(error);

                            }

                        }}
                        >Baixar XML</Button>{' '}
                    </div>
                
                </Col>

            </Row>

            <hr />

            {

                //listar CFE
                totalRegistros > 0
                &&
                <Table>
                    <thead>
                        <tr>
                            <th>Numero</th>
                            <th>Chave</th>
                            <th>Status</th>
                            <th>Data emissão</th>
                            <th>Total nota</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            notasCfe.map((item) => {
                                let statusDestaNota = "";
                                if(item.status == "06000"){
                                    statusDestaNota = "ATIVO";
                                }else if(item.status == "07000"){
                                    statusDestaNota = "CANCELADO";
                                }else{
                                    statusDestaNota = "Erro";
                                }
                                return(
                                    <tr key={item.idCupom}>
                                        <td>{item.idCupom}</td>
                                        <td>{item.chave}</td>
                                        <td>{statusDestaNota}</td>
                                        <td>{item.dataCFeFormatada}</td>
                                        <td>{item.valorTotal != null ? parseFloat(item.valorTotal).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''}</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>

            }

            {

            //listar NFC-e
            totalRegistros > 0
            && notasCfe.map((item) => {

                let statusDestaNota = "";

                if(item.status == "06000"){

                    statusDestaNota = "ATIVO";

                }else if(item.status == "07000"){

                    statusDestaNota = "CANCELADO";

                }else{
                    statusDestaNota = "Erro";
                }

                
            
                return(

                    <Container fluid key={item.idCupom}>

                        <Row>
                            <Col
                            xs={2}
                            sm={2}
                            md={2}
                            lg={2}
                            xl={2}
                            style={{ paddingRight: 1, paddingLeft: 1 }} // Remove paddingRight to remove space between columns
                            >        
                                <FloatingLabel
                                    label="Numero da nota: "
                                    className="mb-0"
                                >
                                    <Form.Control 
                                        type="text"
                                        value={item.idCupom} 
                                        onChange={(e) => {

                                            e.target.value = item.idCupom;

                                        }}
                                    />
                                </FloatingLabel>

                            </Col>
                            <Col
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            style={{ paddingRight: 1, paddingLeft: 1 }} // Remove paddingRight to remove space between columns
                            >
                                
                                <FloatingLabel
                                    label="Chave: "
                                    className="mb-0"
                                >
                                    <Form.Control 
                                        type="text"
                                        value={item.chave} 
                                        onChange={(e) => {

                                            e.target.value = item.chave;

                                        }}
                                    />
                                </FloatingLabel>

                            </Col>
                            <Col
                            xs={2}
                            sm={2}
                            md={2}
                            lg={2}
                            xl={2}
                            style={{ paddingRight: 1, paddingLeft: 1 }} // Remove paddingRight to remove space between columns
                            >

                                <FloatingLabel
                                    label="Status: "
                                    className="mb-0"
                                >
                                    <Form.Control 
                                        type="text"
                                        value={statusDestaNota} 
                                        onChange={(e) => {
                                            e.target.value = statusDestaNota;
                                        }}
                                    />
                                </FloatingLabel>

                            </Col>
                            <Col
                            xs={3}
                            sm={3}
                            md={3}
                            lg={3}
                            xl={3}
                            style={{ paddingRight: 1, paddingLeft: 1 }} // Remove paddingRight to remove space between columns
                            > 
                                <FloatingLabel
                                    label="Data emissão: "
                                    className="mb-0"
                                >
                                    <Form.Control 
                                        type="text"
                                        value={item.dataCFeFormatada} 
                                        onChange={(e) => {

                                            e.target.value = item.dataCFeFormatada;

                                        }}
                                    />
                                </FloatingLabel>
                            </Col>
                            <Col
                            xs={2}
                            sm={2}
                            md={2}
                            lg={2}
                            xl={2}
                            style={{ paddingRight: 1, paddingLeft: 1 }} // Remove paddingRight to remove space between columns
                            >
                                
                                <FloatingLabel
                                    label="Total nota: "
                                    className="mb-0"
                                >
                                    <Form.Control 
                                        type="text"
                                        value={  item.valorTotal != null ? parseFloat(item.valorTotal).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : ''} 
                                        onChange={(e) => {

                                            e.target.value = item.valorTotal != null ? parseFloat(item.valorTotal).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : '';

                                        }}
                                    />
                                </FloatingLabel>

                            </Col>
                        </Row>

                    </Container>

                ); 

            })

            }


            {
            totalRegistros > 0 
            &&<div>

                <hr />

                <Row>

                    <Col>

                        <div className="d-grid gap-2">
                            <Button
                            variant="primary"
                            onClick={() => {

                                console.log("Total Paginas: ", totalPaginas);
                                console.log("Pagina: ", pagina);

                                if(pagina > 1){
                                    setPagina(pagina - 1);
                                }

                            }}
                            ><BsArrowLeftSquare size={30} /></Button>{' '}
                        </div>

                    </Col>
                    
                    <Col>
                        
                        <Form.Control 
                            style={{textAlign: "center", alignItems: "center"}}
                            type="text"
                            value={"Pagina " + pagina + " de " + totalPaginas }
                            onChange={(e) => {

                                e.target.value = "Pagina " + pagina + " de " + totalPaginas;

                            }}
                        />

                    </Col>
                    
                    <Col>

                        <div className="d-grid gap-2">
                            <Button
                            variant="primary"
                            onClick={() => {

                                console.log("Total Paginas: ", totalPaginas);
                                console.log("Pagina: ", pagina);

                                if(pagina < totalPaginas){
                                    setPagina(pagina + 1);
                                }

                            }}
                            ><BsArrowRightSquare size={30} /></Button>{' '}
                        </div>
                    
                    </Col>

                </Row>
            </div>
            }

        </Container>

    );


}
